import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ColorPicker from 'src/components/ColorPicker'
import FontField from 'src/components/FontField'
import FontSelector from 'src/components/FontSelector'
import HeaderTitle from 'src/components/HeaderTitle'
import Loader from 'src/components/Loader'
import Typography from 'src/components/Typography'
import { Button } from 'src/components/ui/button'
import Drawer from 'src/components/ui/drawer'
import { TextField } from 'src/components/ui/textfield'
import { useAppDispatch } from 'src/store'
import {
  useCreateBrandMutation,
  useEditBrandMutation,
  useLazyGetBrandByIdQuery
} from 'src/store/brand/brand.api'
import { BrandsWithCreativeCount, CreateBrandPayload, UserAsset } from 'src/store/brand/brand.type'
import { RtkError } from 'src/store/redux.types'

import IconButton from 'src/components/ui/iconButton'
import { AppRoutes } from 'src/constants/routes.ts'
import useHandleErrorMessage from 'src/hook/useIsErrorMessage'
import useIsMobile from 'src/hook/useIsMobile'
import BrandLogoUploader from 'src/pages/Brand/AddEditBrand/BrandLogoUploader'
import { setSelectedBrand } from 'src/store/brand/brand.slice.ts'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { trackEvent } from '../../../amplitude'
import { events } from '../../../amplitude/data.ts'
import fonts from '../../../data/fonts.json'
import SuccessBrand from '../SucceessBrand'
import ColorPallettes from './ColorPallettes'
import PresetFonts from './PresetFonts'

const AddEditBrand = () => {
  const isMobile = useIsMobile()
  const { brandId } = useParams()
  const handleErrorMessage = useHandleErrorMessage()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isPalletes, setIsPalletes] = useState(false)
  const [brand, setBrand] = useState<BrandsWithCreativeCount>()
  const [createBrand, { isLoading: createBrandLoading }] = useCreateBrandMutation()
  const [editBrand, { isLoading: editBrandLoading }] = useEditBrandMutation()
  const [getBrandById, { isLoading, isError, error }] = useLazyGetBrandByIdQuery()
  const [isImageLoading, setIsImageLoading] = useState<boolean>(false)
  const [isFontPallets, setIsFontPallets] = useState<boolean>(false)
  const [isSecondaryFont, setSecondaryFont] = useState(false)

  useEffect(() => {
    fonts.forEach(({ name, url }) => {
      // Fetch the CSS file to extract font URLs
      fetch(url)
        .then(response => response.text())
        .then(css => {
          // Extract font URLs from CSS
          const matches = css.match(/url\((.*?)\)/g)
          if (matches) {
            const fontUrls = matches.map(match => match.slice(4, -1)) // Remove 'url(' and ')'
            // Load each font
            fontUrls.forEach(fontUrl => {
              const font = new FontFace(name, `url(${fontUrl})`)
              font
                .load()
                .then(loadedFont => {
                  document.fonts.add(loadedFont)
                })
                .catch(() => {
                  // console.error(`Error loading font "${name}":`, error)
                })
            })
          }
        })
        .catch(() => {
          // console.error(`Error fetching CSS for font "${name}":`, error)
        })
    })
  }, [])

  useEffect(() => {
    if (isError && error) {
      handleErrorMessage((error as RtkError).data.error.message, true)
    }
  }, [error, isError])

  useEffect(() => {
    if (brandId) {
      getBrandById(brandId)
        .unwrap()
        .then(res => {
          setBrand(res)
          setValues({
            name: res.name,
            webUrl: res.webUrl,
            logoAssets: res.logoAssets,
            color: res.color,
            primaryFonts: res.primaryFonts,
            secondaryFonts: res.secondaryFonts,
            description: res.description,
            creativesCount: res.creativesCount
          })
        })
        .catch((error: RtkError) => {
          if (error) {
            // dispatch(
            //   setSnackbar({ severity: SnackbarType.ERROR, message: error.data.error.message })
            // )
            handleErrorMessage(error.data.error.message, true)
          }
        })
    }
  }, [])

  const checkHttps = (url: string) => {
    if (url.startsWith('http://') || url.startsWith('https://')) {
      return url
    } else {
      return url ? 'https://' + url : ''
    }
  }

  const {
    values,
    setFieldValue,
    touched,
    errors,
    // isValid,
    setFieldTouched,
    handleSubmit,
    // dirty,
    setValues
  } = useFormik({
    initialValues: {
      name: brand?.name ?? '',
      webUrl: checkHttps(brand?.webUrl ?? ''),
      logoAssets: brand?.logoAssets ?? [],
      color: {
        primary: brand?.color?.primary ?? '#E1AB1F',
        secondary: brand?.color?.secondary ?? '#7D7D7D',
        accent: brand?.color?.accent ?? '#477361'
      },
      primaryFonts: brand?.primaryFonts ?? ['Roboto'],
      secondaryFonts: brand?.secondaryFonts ?? ['Raleway'],
      description: brand?.description ?? '',
      creativesCount: brand?.creativesCount ?? 18
    },
    validationSchema: toFormikValidationSchema(
      z.object({
        name: z.string({ required_error: 'Please enter brand name.' }),
        webUrl: z
          .string({ required_error: 'Please enter website url.' })
          .refine(
            url => {
              return /^(?:(?:https?|ftp):\/\/)?([a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)?)+(\/[^\s]+)?$/.test(
                url
              )
            },
            {
              message: 'Invalid Website URL'
            }
          )
          .refine(
            url => {
              return url.length > 0
            },
            {
              message: 'Website URL is required'
            }
          ),
        logoAssets: z.array(z.object({})).nonempty({ message: 'Please select logo.' }),
        primaryFonts: z.array(z.string()).nonempty({ message: 'Please select logo.' }),
        secondaryFonts: z.array(z.string()).nonempty({ message: 'Please select logo.' })
      })
    ),
    onSubmit: values => {
      const updatedValues: BrandsWithCreativeCount = {
        ...values,
        webUrl: checkHttps(values.webUrl)
      }
      if (brandId) {
        editBrand({
          brandId,
          values: updatedValues
        })
          .then(res => {
            if ('data' in res) {
              setBrand(res.data)
              trackEvent(events.USER_FLOW_BRAND_EDIT_SUCCESS, {
                brand_id: res.data.uuid
              })
              navigate(AppRoutes.BRAND_LIST)
            }
          })
          .catch((error: RtkError) => {
            if (error) {
              // dispatch(
              //   setSnackbar({ severity: SnackbarType.ERROR, message: error.data.error.message })
              // )
              trackEvent(events.USER_FLOW_BRAND_EDIT_FAILURE, {
                failure_reason: error.data.error.message
              })
              handleErrorMessage(error.data.error.message, true)
            }
          })
      } else {
        if (Array.isArray(values.logoAssets)) {
          // Create a new array with objects excluding the pathName property
          const newPathArray = values.logoAssets.map(i => ({ uuid: i.uuid }))

          const payload: CreateBrandPayload = {
            color: values.color,
            name: values.name,
            webUrl: updatedValues.webUrl,
            description: values.description,
            primaryFonts: values.primaryFonts,
            secondaryFonts: values.secondaryFonts,
            logoAssets: [...newPathArray]
          }
          createBrand(payload)
            .then(res => {
              if ('data' in res) {
                dispatch(setSelectedBrand(res.data))
                setBrand(res.data)
                trackEvent(events.BRAND_CREATION_BRAND_CREATED_SUCCESS, {
                  brand_id: res.data.uuid
                })
                setIsSuccess(true)
              }
            })
            .catch((error: RtkError) => {
              if (error) {
                trackEvent(events.BRAND_CREATION_BRAND_CREATED_FAILURE, {
                  failure_reason: error.data.error.message
                })
                handleErrorMessage(error.data.error.message, true)
              }
            })
        }
      }
    },
    enableReinitialize: true,
    validateOnMount: false
  })

  const handleChange = (key: string, value: string | UserAsset[] | string[]) => {
    setFieldTouched(key, true)
    setFieldValue(key, value)
  }
  const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    const id = e.target.name
    const value = e.target.value
    trackEvent(events.BRAND_CREATION_TEXT_FIELD_ADDED, {
      text_field: id,
      text_length: value.length,
      value: value
    })
  }

  const handleCombinationFontChange = (key1: string, key2: string, value: string[]) => {
    setFieldTouched(key1, true)
    setFieldValue(key1, [value[0]])

    setFieldTouched(key2, true)
    setFieldValue(key2, [value[1]])

    trackEvent(events.BRAND_CREATION_FONTS_PAIR_SELECTED, {
      primary_font: value[0],
      secondary_font: value[1]
    })
  }

  return (
    <>
      {isLoading ? (
        <div className="h-[calc(100vh-136px)] flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <>
          {brand && brand?.uuid && isSuccess ? (
            <SuccessBrand brandName={brand.name} brandId={brand.uuid} />
          ) : (
            <div className="py-[20px] mx-4 gap-[30px] max-w-[1640px]">
              {!isPalletes ? (
                <Drawer
                  size={isMobile ? '100%' : '40%'}
                  header={'BRAND FONT'}
                  isOpen={isOpen}
                  onClose={() => {
                    setIsOpen(false)
                    setSecondaryFont(false)
                  }}>
                  <FontSelector
                    handleClose={() => {
                      setIsOpen(false)
                      setSecondaryFont(false)
                    }}
                    handleFont={font => {
                      if (isSecondaryFont) {
                        handleChange('secondaryFonts', [font])
                        trackEvent(events.BRAND_CREATION_FONTS_PAIR_SELECTED, {
                          secondary_font: font
                        })
                      } else {
                        handleChange('primaryFonts', [font])
                        trackEvent(events.BRAND_CREATION_FONTS_PAIR_SELECTED, {
                          primary_font: font
                        })
                      }
                    }}
                    fontSelect={isSecondaryFont ? values.secondaryFonts[0] : values.primaryFonts[0]}
                  />
                </Drawer>
              ) : (
                <Drawer
                  size={isMobile ? '100%' : '35%'}
                  header={<HeaderTitle primaryName="PRESET" secondaryName="PALLETTES" />}
                  isOpen={isPalletes}
                  onClose={() => setIsPalletes(false)}>
                  <ColorPallettes
                    handleChange={handleChange}
                    handleClose={() => setIsPalletes(false)}
                    colors={values.color}
                  />
                </Drawer>
              )}
              {isFontPallets && (
                <Drawer
                  size={isMobile ? '100%' : '30%'}
                  header={<HeaderTitle primaryName="PRESET" secondaryName="FONTS" />}
                  isOpen={isFontPallets}
                  onClose={() => {
                    setIsFontPallets(false)
                    setIsOpen(false)
                  }}>
                  <PresetFonts
                    handleClose={() => {
                      setIsFontPallets(false)
                      setIsOpen(false)
                    }}
                    handleCombinationFontChange={handleCombinationFontChange}
                  />
                </Drawer>
              )}
              <HeaderTitle primaryName={brandId ? 'EDIT' : 'CREATE'} secondaryName="A BRAND" />
              <form id="brand-form" name="barnd-form" onSubmit={handleSubmit}>
                <div className="flex py-[30px] gap-[20px] justify-between">
                  <div className="flex gap-[20px] flex-wrap">
                    <div className="flex flex-col gap-[10px] ">
                      <Typography size={14} className="font-[700] leading-[18px] text-text-primary">
                        BRAND NAME
                      </Typography>

                      <TextField
                        variant={'rounded'}
                        placeholder="Your brand name"
                        textLimit={300}
                        name="name"
                        id="name"
                        className="bg-white"
                        value={values.name.trimStart()}
                        onBlur={e => handleBlur(e)}
                        width="100%"
                        handleOnChange={(key, value) => handleChange(key, value)}
                        errorText={touched.name && (errors.name as string)}
                      />
                    </div>
                    <div className="flex flex-col gap-[10px]">
                      <Typography size={14} className="font-[700] leading-[18px] text-text-primary">
                        WEBSITE URL
                      </Typography>
                      <TextField
                        variant={'rounded'}
                        placeholder="Your website"
                        name="webUrl"
                        id="webUrl"
                        className="bg-white"
                        value={values.webUrl.trimStart()}
                        onBlur={e => handleBlur(e)}
                        width="100%"
                        handleOnChange={(key, value) => handleChange(key, value)}
                        errorText={touched.webUrl && (errors.webUrl as string)}
                      />
                    </div>
                  </div>
                </div>
                <div className="p-[30px] bg-secondary-primary min-h-[705px] rounded-[5px]">
                  <Typography size={16} className="font-[900] leading-[18px] text-text-primary">
                    LOGO
                  </Typography>

                  <div className="py-[10px]">
                    {/* <ImageUploader
                      onFileSelect={images => handleChange('path', images)}
                      imageList={values.path}
                      errorText={errors.path as string}
                      maxImages={6}
                    /> */}
                    <BrandLogoUploader
                      onFileSelect={images => handleChange('logoAssets', images as UserAsset[])}
                      assetList={values.logoAssets}
                      errorText={errors.logoAssets as string}
                      maxImages={6}
                      setIsImageLoading={setIsImageLoading}
                    />
                    {touched.logoAssets && (
                      <div className={'flex flex-1 text-error mt-1 text-[14px] px-2 '}>
                        {errors.logoAssets as string}
                      </div>
                    )}
                  </div>

                  <div className="py-[50px]">
                    <Typography
                      size={16}
                      className="font-[900] leading-[18px] mb-[10px] text-text-primary">
                      COLOR PALETTE
                    </Typography>
                    <div className="flex gap-[20px] flex-wrap">
                      <div className="flex gap-[20px] flex-wrap">
                        <ColorPicker
                          name="Primary"
                          selectedColor={values.color.primary}
                          handleColor={color => handleChange('color.primary', color)}
                        />
                        <ColorPicker
                          name="Secondary"
                          selectedColor={values.color.secondary}
                          handleColor={color => handleChange('color.secondary', color)}
                        />
                        <ColorPicker
                          name="Accent"
                          selectedColor={values.color.accent}
                          handleColor={color => handleChange('color.accent', color)}
                        />
                      </div>
                      <div
                        onClick={() => setIsPalletes(true)}
                        className="flex items-center gap-[10px] h-[fit-content] bg-white border-[1px] border-main-primary rounded-full pl-1 pt-1 pb-1 pr-5 cursor-pointer">
                        <IconButton path="/assets/svgs/pallettes.svg" size={'md'} />
                        <Typography size={12} className="font-[800]">
                          PRESET PALLETTES
                        </Typography>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col ">
                    <Typography
                      size={16}
                      className="font-[900] leading-[18px] mb-[10px] text-text-primary">
                      BRAND FONT
                    </Typography>

                    <div className="flex gap-[30px] flex-wrap">
                      <FontField
                        name={values.primaryFonts[0]}
                        handleClick={() => {
                          setIsFontPallets(false)
                          setIsOpen(true)
                        }}
                      />

                      <FontField
                        name={values.secondaryFonts[0]}
                        handleClick={() => {
                          setIsOpen(true)
                          setSecondaryFont(true)
                        }}
                      />

                      <div
                        onClick={() => setIsFontPallets(true)}
                        className="flex ml-0 md:ml-5 items-center gap-[10px] h-[fit-content] bg-white border-[1px] border-main-primary rounded-full pl-1 pt-1 pb-1 pr-5 cursor-pointer">
                        <IconButton path="/assets/svgs/pallettes.svg" size={'md'} />
                        <Typography size={12} className="font-[800]">
                          PRESET FONTS
                        </Typography>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col py-[30px] gap-[10px] max-w-[1640px] text-text-primary">
                    <Typography size={16} className="font-[900] leading-[18px] ">
                      BRAND DESCRIPTION
                    </Typography>

                    <TextField
                      variant={'rounded'}
                      placeholder="Describe your brand's purpose in 300 characters"
                      showCount
                      textLimit={300}
                      name="description"
                      id="description"
                      className="bg-white"
                      onBlur={e => handleBlur(e)}
                      value={values.description.trimStart()}
                      handleOnChange={(key, value) => handleChange(key, value)}
                      width={isMobile ? '100%' : '40%'}
                      errorText={touched.description && (errors.description as string)}
                      initialValue={values.description}
                    />
                  </div>

                  <div className="flex justify-end pt-6">
                    <Button
                      type="submit"
                      label={createBrandLoading || editBrandLoading ? 'SAVING...' : 'SAVE BRAND'}
                      disabled={isImageLoading}
                      className="px-[20px] py-[10px] font-[900] text-[14px]"
                    />
                  </div>
                </div>
              </form>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default AddEditBrand
