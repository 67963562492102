import { debounce, orderBy } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import assets from 'src/assets'
import Typography from 'src/components/Typography'
import { Button } from 'src/components/ui/button'
import IconButton from 'src/components/ui/iconButton'
import { TextField } from 'src/components/ui/textfield'
import fonts from 'src/data/fonts.json'

interface FontSelectorProps {
  handleFont: (font: string) => void
  handleClose: () => void
  fontSelect: string
}

interface HandleOnClickProps {
  name: string
  url?: string
}

const FontSelector = ({ handleFont, handleClose, fontSelect }: FontSelectorProps) => {
  const containerRef = React.useRef<HTMLDivElement>(null!)

  const [selectedFont, setSelectedFont] = useState<string>(fontSelect)
  const [searchString, setSearchString] = useState<string>('')

  useEffect(() => {
    containerRef?.current?.scrollIntoView()
  }, [selectedFont])

  const handleOnClick = ({ name, url }: HandleOnClickProps) => {
    setSelectedFont(name)
    const font = new FontFace(name, `url(${url})`)
    document.fonts.add(font)
    font.load()
  }
  const handleOnChange = useCallback(
    debounce((value: string) => {
      setSearchString(value)
    }, 500),
    []
  )

  const filteredFonts = fonts.filter(font =>
    font.name.toLowerCase().includes(searchString.toLowerCase())
  )

  return (
    <div>
      <TextField
        fullWidth
        variant={'rounded'}
        placeholder="Search Font"
        className="bg-white"
        id="fonts"
        rightIcon={<img alt="search-icon" src={assets.svgs.SEARCH} className="w-5 h-5 mr-2 " />}
        handleOnChange={(_, value) => handleOnChange(value)}
      />

      <div className="max-h-[383px] mt-5 overflow-y-scroll scrollbar-hide flex flex-col mb-6">
        {filteredFonts.length > 0 ? (
          orderBy(filteredFonts, 'name').map(({ id, name, url }) => {
            return (
              <div
                key={id}
                ref={selectedFont === name ? containerRef : null}
                style={{ fontFamily: name }}
                onClick={() => handleOnClick({ name, url })}
                className={`rounded-lg flex  items-center justify-between cursor-pointer py-3 px-5  hover:bg-white ${
                  selectedFont === name && 'bg-white'
                } `}>
                <Typography className="flex items-center h-[32px]">{name}</Typography>
                {selectedFont === name && (
                  <IconButton size={'md'} variant={'secondary'} path={assets.svgs.TICK} />
                )}
              </div>
            )
          })
        ) : (
          <div className="mx-auto my-3">No data found!</div>
        )}
      </div>
      {filteredFonts.length > 0 && (
        <>
          {/* {!!selectedFont && (
            <Typography
              style={{ fontFamily: selectedFont, fontSize: 30 }}
              className="mb-5 bg-white rounded-lg px-5 py-3 ">
              This is how this font looks
            </Typography>
          )} */}
          <Button
            label="SAVE"
            className="ml-2 px-12 mb-3"
            onClick={() => {
              handleFont(selectedFont)
              handleClose()
            }}
          />
        </>
      )}
    </div>
  )
}

export default FontSelector
