import { useLayoutEffect, useRef, useState } from 'react'
import ReactCrop, { PixelCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import assets from 'src/assets'
import { useAppDispatch } from 'src/store'
import { setSnackbar } from 'src/store/snackbar/snackbar.slice'
import { SnackbarType } from 'src/store/snackbar/snackbar.type'
import { trackEvent } from '../../../amplitude'
import { events } from '../../../amplitude/data.ts'
import { useParams } from 'react-router-dom'
import { useStateSelector } from '../../../store/root.reducer.ts'
import { isGuest } from 'src/utils/cookies.ts'

interface CropImageProps {
  url: string
  setCropMode: (b: boolean) => void
  cropPos: PixelCrop | undefined
  setCropPos: (c: PixelCrop) => void
  rotationAngle: number
}

const getDefautltCrop = (image: HTMLImageElement) => {
  const aspectRatio = image.naturalWidth / image.naturalHeight

  // Get the width and height of the container
  const containerWidth = image.clientWidth
  const containerHeight = image.clientHeight

  let renderedWidth, renderedHeight, x, y

  // Calculate the rendered width and height based on the aspect ratio and container dimensions
  if (containerWidth / containerHeight > aspectRatio) {
    // Container is wider than the image (based on aspect ratio)
    y = 0
    renderedHeight = containerHeight
    renderedWidth = containerHeight * aspectRatio
    x = (containerWidth - renderedWidth) / 2
  } else {
    // Container is taller than the image (based on aspect ratio)
    renderedWidth = containerWidth
    x = 0
    renderedHeight = containerWidth / aspectRatio
    y = (containerHeight - renderedHeight) / 2
  }
  return {
    x,
    y,
    width: renderedWidth,
    height: renderedHeight,
    unit: 'px'
  } as PixelCrop
}

const CropImage = ({
  url: dataUrl,
  setCropMode,
  cropPos,
  setCropPos,
  rotationAngle
}: CropImageProps) => {
  const imgRef = useRef<HTMLImageElement>(null)
  const [crop, setCrop] = useState<PixelCrop | undefined>(cropPos)
  const [locked, setLocked] = useState(false)
  const dispatch = useAppDispatch()
  const { brandId } = useParams()
  const creativeDetails = useStateSelector(state => state.creativeSlice)
  useLayoutEffect(() => {
    if (!imgRef.current) {
      return
    }
    if (!crop) {
      setCrop(getDefautltCrop(imgRef.current))
    }
  }, [])

  const onSaveCroppedImage = () => {
    setCropPos(crop!)
    setCropMode(false)
    trackEvent(events.AD_CREATION_IMAGE_EDIT, {
      brand_id: brandId,
      project_name: creativeDetails.projectName,
      session_type: isGuest() ? 'guest' : 'registered',
      activity: 'cropped'
    })
    dispatch(
      setSnackbar({
        severity: SnackbarType.SUCCESS,
        message: 'Image cropped successfully!'
      })
    )
  }

  return (
    <div className="App flex flex-col gap-4">
      <ReactCrop
        className="relative z-10"
        crop={crop}
        onChange={crop => setCrop(crop)}
        minHeight={100}
        locked={locked}>
        <div className="relative ">
          <img
            ref={imgRef}
            alt="Crop me"
            src={dataUrl}
            crossOrigin="anonymous"
            className="object-contain h-[370px]"
            style={{
              transform: `rotate(${rotationAngle}deg)`
            }}
          />
          <div
            onClick={e => {
              e.stopPropagation()
              onSaveCroppedImage()
            }}
            onMouseEnter={() => setLocked(true)}
            onMouseLeave={() => setLocked(false)}
            style={{ backgroundColor: 'rgba(0, 0, 0, 0.60)' }}
            className="absolute top-4 right-2 z-[99] cursor-pointer rounded-full  ">
            <img src={assets.svgs.TICK} alt="reverse" className="m-[10px]" />
          </div>
        </div>
      </ReactCrop>
    </div>
  )
}

export default CropImage
