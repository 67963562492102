import { useEffect, useState } from 'react'
import assets from 'src/assets'
import Typography from 'src/components/Typography'
import { Button } from 'src/components/ui/button'
import combinationFonts from 'src/data/fontsCombination.json'
import { trackEvent } from '../../../amplitude'
import { events } from '../../../amplitude/data.ts'

interface ColorPallettesPropsTypes {
  //   handleChange: (key: string, value: string) => void
  handleCombinationFontChange: (key1: string, key2: string, value: string[]) => void
  handleClose: () => void
}

const PresetFonts = ({ handleClose, handleCombinationFontChange }: ColorPallettesPropsTypes) => {
  const [selectedId, setSelectedId] = useState<number>()

  const selectedFontCombination = combinationFonts.find(i => i.id === selectedId)
  useEffect(() => {
    trackEvent(events.BRAND_CREATION_FONTS_PAIR_VIEWED)
  }, [])
  return (
    <>
      <div
        className="flex flex-col gap-5 cursor-pointer h-[70%] overflow-auto scrollbar-hide"
        style={{ maxHeight: ' calc(100vh - 200px)' }}>
        {combinationFonts.map(i => {
          return (
            <div
              className=" flex gap-[3px]"
              onClick={() => {
                setSelectedId(i.id)
              }}
              key={i.id}>
              <div
                className="relative bg-white p-[20px] rounded-[10px] font-[500] "
                style={{
                  backgroundColor: selectedId == i.id ? '#598081' : '',
                  color: selectedId == i.id ? '#fff' : '',
                  fontFamily: i.name[0]
                }}>
                <Typography size={16}>{i.name[0]}</Typography>
                {i.id === selectedId && (
                  <div className="absolute right-[-16px] top-[40%] ">
                    <img src={assets.svgs.GREEN_TICK} alt="tick" className="w-[60%] m-auto" />
                  </div>
                )}
              </div>

              <div
                className="bg-white p-[20px] rounded-[10px]"
                style={{
                  backgroundColor: selectedId == i.id ? '#598081' : '',
                  color: selectedId == i.id ? '#fff' : '',
                  fontFamily: i.name[1]
                }}>
                <Typography size={16}>{i.name[1]}</Typography>
              </div>
            </div>
          )
        })}
      </div>
      <Button
        disabled={!selectedId}
        label="SAVE"
        onClick={() => {
          if (selectedFontCombination) {
            handleCombinationFontChange(
              'primaryFonts',
              'secondaryFonts',
              selectedFontCombination.name
            )
          }
          handleClose()
        }}
        fullWidth={false}
        className="flex my-10  text-[14px] font-[900] px-[30px] py-[7px]"
      />
    </>
  )
}

export default PresetFonts
